<template>
  <div class="main" v-if="surveysOptions">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>{{ surveysOptions.name }}</span>
    </div>
    <div class="main-content-fa">
      <div class="main-content">
        <div class="line-box">
          <div class="line-title">{{ surveysOptions.number }}</div>
          <img class="icon-discuss" src="@/assets/image/icon-discuss.png" />
        </div>
        <div class="line-box">
          <div class="line-title">{{ surveysOptions.name }}</div>
        </div>
        <div class="line"></div>
        <div class="line-box2">
          <div class="line-title">金币数量</div>
          <div class="line-right">
            <span>{{ surveysOptions.points }}</span> 金币
          </div>
        </div>
        <div class="line-box2">
          <div class="line-title">时长</div>
          <div class="line-right">{{ surveysOptions.loi }}分钟</div>
        </div>
        <div class="line-box2">
          <div class="line-title">难易度</div>
          <div class="line-right">{{ surveysOptions.ir }}%</div>
        </div>
        <div class="line-box2" v-if="add">
          <div class="line-title">信用值加成</div>
          <div class="grade-box">
            <img
              class="icon-grade"
              :src="require(`@/assets/image/credit${grade}.png`)"
            />
            <span class="grade-number">{{ add }}%</span>
            <img class="arrow-top" src="@/assets/image/arrow-top.png" />
          </div>
        </div>
        <div class="line"></div>

        <div class="title-box">任务详情</div>

        <div v-html="surveysOptions.describe"></div>
      </div>
      <div class="btn" @click="goUrl(surveysOptions.surveyLink)">进入问卷</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      surveysOptions: null,
      add: 0,
      grade: 1
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goUrl(path) {
      location.href = process.env.VUE_APP_SERVER + path;
    }
  },
  async created() {
    console.log("this", this.$route.params.id);
    const id = this.$route.params.id;
    if (this.$route.query.add) {
      this.add = parseInt(this.$route.query.add);
    }
    this.grade = this.$route.query.grade;
    const res = await this.$http.get(`/api/v1/Surveys/${id}`, {
      params: {
        id
      }
    });
    if (res.status === 200) {
      console.log("res", res);
      this.surveysOptions = res.data;
      document.title = this.surveysOptions.name;
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #ffffff;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: calc(100% - 30px);
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 15px;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #ffffff;
    img {
      width: 20px;
      position: absolute;
      left: 0;
    }
  }
  .main-content {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-top: 12px;
    .line-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;
      .icon-discuss {
        width: 20px;
      }
      .line-title {
        font-size: 15px;
        font-weight: bolder;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
      margin-top: 6px;
      margin-bottom: 14px;
    }
    .line-box2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .line-title {
        font-size: 13px;
        color: #878787;
      }
      .line-right {
        font-size: 12px;
        color: #232323;
        span {
          color: #ff6017;
          font-size: 20px;
          font-weight: bolder;
        }
      }
    }
    .title-box {
      margin-top: 15px;
      color: #232323;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .btn {
    width: calc(100% - 30px);
    height: 46px;
    background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
    border-radius: 25px 25px 25px 25px;
    text-align: center;
    line-height: 46px;
    color: #ffffff;
    margin: 40px 15px;
  }
  .main-content-fa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 40px);
  }
  .grade-box {
    display: flex;
    align-items: flex-end;
    .icon-grade {
      width: 20px;
      margin-right: 6px;
    }
    .grade-number {
      color: #ffa200;
      font-size: 16px;
      font-weight: bolder;
    }
    .arrow-top {
      width: 12px;
      margin-left: 5px;
      // margin-right: 20px;
    }
    .gold-number {
      font-size: 20px;
      color: #ff6017;
      font-weight: bolder;
      margin-right: 6px;
      position: relative;
      top: 4px;
    }
    .name {
      font-size: 10px;
      color: #232323;
    }
  }
}
</style>
